.ant-carousel .slick-dots {
  margin-left: 0px !important;
}

.ant-dropdown-menu
  ant-dropdown-menu-root
  ant-dropdown-menu-vertical
  ant-dropdown-menu-light
  ant-dropdown-menu-without-submenu {
  font-family: "Noto " !important;
}
/* .ant-layout-sider {
  font-family: "Phetsarath" !important;
} */

.slider img {
  height: 100%;
  object-fit: cover;

  margin-top: 0px;
}

/* .background-animate {
  background-size: 400%;

  -webkit-animation: AnimationName 3s ease infinite;
  -moz-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
} */
/* 
.ant-menu-submenu > .ant-menu {
  max-height: 500px !important;
  overflow: auto !important;
} */

.slick-slide > div {
  margin: 0 10px;
}
.slick-list {
  margin: 0 -10px;
}

.card {
  border: 1px solid #fff;
  background: black;
  border-radius: 8px;
  overflow: hidden;

  height: 400px;
  color: #fff;
  cursor: pointer;
}

.card-top h1 {
  font-size: 0.9rem;
  margin: 20px;
}

.card-top > img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  background: black;
}

.card-bottom {
  margin: 10px 20px;
}

.category {
  position: relative;
}
.category::before {
  content: "";
  background: rgb(255, 61, 61);
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  right: 0;
  height: 2px;
}

.ant-layout .ant-layout-has-sider {
  max-width: 500px !important;
  min-width: 500px !important;
}
