/* @import url("https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Noto+Serif+Lao:wght@100;200;300;400;500;600;700;800;900&display=swap"); */

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Noto", serif !important;
  padding: 0;
  margin: 0;
}
@font-face {
  font-family: "Noto";
  /* src: url("../public/assets/fonts/NotoSansLao-VariableFont_wdth,wght.ttf"); */
  src: url("../public/assets/fonts/NotoSerifLao-VariableFont_wdth,wght.ttf");
}

/* @font-face {
  font-family: "lao";
  src: url("https://fonts.googleapis.com/css2?family=Noto+Serif+Lao:wght@100;200;300;400;500;600;700;800;900&display=swap");
} */

.pink-btn {
  @apply bg-pink-500 text-white py-[5px] px-[30px] text-sm hover:bg-pink-300 rounded-full;
}
.green-btn {
  @apply bg-green-500 mr-1 hover:bg-green-700 py-2 px-3 rounded text-sm leading-3 text-white focus:outline-none;
}
.orange-btn {
  @apply bg-orange-500 mr-1 hover:bg-pink-700 py-2 px-3 rounded text-sm leading-3 text-white focus:outline-none;
}
.header-content-doctor-detail {
  @apply font-semibold text-center text-xl border-b-2 bg-pink-300 rounded-t-xl text-gray-700;
}
.header-gradient {
  @apply text-center font-Noto font-semibold text-2xl text-white pt-5 pb-3 bg-pink-400 rounded-t-xl
        bg-gradient-to-r from-violet-500 to-fuchsia-500;
}
.form-control {
  @apply block
  w-full
  px-3
  py-1.5
  text-base
  font-normal
  text-gray-700
  bg-white bg-clip-padding
  border border-solid border-gray-300
  rounded
  transition
  ease-in-out
  m-0
  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none;
}

.team-profile {
  @apply text-center px-6 py-4;
}
.team-profile img {
  @apply w-32 h-32 object-cover rounded-full object-top;
}
.team-profile h3 {
  @apply font-bold text-xl;
}
.team-profile p {
  @apply text-sm text-gray-600;
}
